import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const EventRedirect = () => {
  const { eventId } = useParams()

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    let deepLink = ''
    let fallbackLink = ''

    if (/android/i.test(userAgent)) {
      // Android device: construct deep link and fallback URL.
      deepLink = `https://play.google.com/store/apps/`
      fallbackLink = 'https://play.google.com/store/apps/'
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS device: construct deep link and fallback URL.
      deepLink = `sqdfy://app/event?id=${eventId}`
      fallbackLink = 'https://apps.apple.com/'
    } else {
      // For other devices (e.g., desktop), you might want to send them to a web page.
      window.location = 'https://apps.apple.com/'
      return
    }

    // Attempt to open the deep link.
    window.location = deepLink

    // If the app is not installed, fallback to the store after a delay.
    setTimeout(() => {
      window.location = fallbackLink
    }, 2000)
  }, [eventId])

  return <div>Redirecting...</div>
}
